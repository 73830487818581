import { LexemeData, Data, DataContent } from './types'

interface Props {
  exampleCounter?: number
  swedishData?: LexemeData
  data: Data
  lang?: string
}

const Definition = (props: Props) => {
  let definition = ''
  let data: Data | LexemeData

  if (props.swedishData !== undefined) {
    data = props.swedishData

    if (data.Definition !== undefined) {
      definition = data.Definition
    }
  } else {
    data = props.data

    if (data.Meaning !== undefined) {
      definition = data.Meaning
    }
  }

  if (definition === '') {
    if (data.Gramcom !== undefined) {
      definition = data.Gramcom.Text
    } else if (props.lang === 'swe' && data.Comment !== undefined) {
      data.Comment.forEach((element: DataContent) => {
        if (element.Text !== undefined && element.Text.length > 0) {
          definition = element.Text
        }
      })
    }
  }

  const definitionCounter = (data?: Data, swedishData?: LexemeData) => {
    if (data !== undefined && swedishData !== undefined) {
      if (data.Lexeme !== undefined && data.Lexeme.length > 1) {
        return <span>{data.Lexeme.indexOf(swedishData) + 1}. </span>
      }
    }
  }

  if (definition.length > 0) {
    return (
      <div className='definition'>
        {/* Show number counter before examples in english version */}
        {props.exampleCounter! >= 0 && (
          <span>{props.exampleCounter! + 1}. </span>
        )}
        {definitionCounter(props.data, props.swedishData)}
        {/* TODO Considering refactor to use replaceAll */}
        {definition
          .replace(/\S*\(\d\)|\S*\(\d,.\d\)|(&lt;i&gt;)|(&lt;\/i&gt;)/g, '')
          .replace(/\s\s/g, ' ')
          .replace(/\s,|\s;/g, ',')}
      </div>
    )
  } else {
    return <></>
  }
}

export default Definition
