import Dropdown from 'react-bootstrap/Dropdown'
import { Options } from './types'

interface Props {
  chosenLanguageLabel: string
  options: Options[]
  changeLanguage: (value: string, label: string) => void
}

const Language = (props: Props) => {
  const changeLanguage = (eventKey: any) => {
    const { options } = props
    const selectedLanguage = eventKey
    let label = ''

    for (var i = 0; i < options.length; i++) {
      if (options[i].value === selectedLanguage) {
        label = options[i].show
        break
      }
    }

    localStorage.setItem('langValue', selectedLanguage)

    if (label !== '') {
      localStorage.setItem('langLabel', label)
    }

    props.changeLanguage(selectedLanguage, label)
  }

  return (
    <div
      className='language-menu-container'
      data-testid='language-div-container'
    >
      <Dropdown className='language-menu-dropdown' onSelect={changeLanguage}>
        <Dropdown.Toggle
          className='pb-0 pt-0 pr-0'
          variant='link'
          id='dropdown-basic'
        >
          <span className='card-text'>
            Svensk-
            <span className='link chosen-language-label'>
              {props.chosenLanguageLabel}
            </span>{' '}
            ordbok
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.options.map(item => {
            return (
              <Dropdown.Item key={item.value} eventKey={item.value}>
                {item.text}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Language
