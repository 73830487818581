/**
 * This function takes in the array of objects, which is received when calling:
 * https://ordboken.orilango.se/eng/?language=eng&word=${word}
 * More info about the API: https://github.com/oribisoftware/ordboken-api
 * It then returns a new array of objects grouped by word and their word classes:
 * noun, adjective, verb, adverb
 * - Every word with unique word class includes a array of associated Inflections if available
 * - and the array data including associated Meaning, Synonyms, Antonyms and Examples if available,
 * which are separated per post.
 * See: src/Dictionary/__test__/groupNyWordClass.test.js for a detailed look of the object structure before and after
 * this function has been called.
 */

import { EnglishData } from './types'

interface EnglishMassagedData {
  match: string
  word: string
  wordType: string
  Inflection?: { Text: string }[]
  data: {
    Meaning: string
    Synonym?: { Text: string }[]
    Antonym?: { Text: string }[]
    Example?: { Text: string }[]
  }[]
}

function groupByWordClass(arr: EnglishData[]): EnglishMassagedData[] {
  return arr.reduce((outputArray: EnglishMassagedData[], currentValue) => {
    // Find index of the current WORD
    const indexOfWord = outputArray.findIndex(
      x => x.word === currentValue.data.Value
    )

    // Find index of the current TYPE/CLASS
    const indexOfType = outputArray.findIndex(
      x => x.wordType === currentValue.data.Type
    )

    // If WORD or TYPE/CLASS does not exists, add new element to the array
    // otherwise add element to related object
    if (indexOfWord === -1 || indexOfType === -1) {
      outputArray.push({
        match: currentValue.match,
        word: currentValue.data.Value,
        wordType: currentValue.data.Type,
        Inflection: currentValue.data.Inflection,
        data: [
          {
            Meaning: currentValue.data.Meaning,
            Synonym: currentValue.data.Synonym,
            Antonym: currentValue.data.Antonym,
            Example: currentValue.data.Example
          }
        ]
      })
    } else {
      outputArray.forEach((element, i) => {
        if (
          currentValue.data.Value === element.word &&
          currentValue.data.Type === element.wordType
        ) {
          outputArray[i].data.push({
            Meaning: currentValue.data.Meaning,
            Synonym: currentValue.data.Synonym,
            Antonym: currentValue.data.Antonym,
            Example: currentValue.data.Example
          })
        }
      })
    }

    return outputArray
  }, [])
}

export default groupByWordClass
