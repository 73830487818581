import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { DataContent, LexemeData, Data, AccordionContentData } from './types'
import { useTranslation } from 'react-i18next'

interface Props {
  data: Data | LexemeData
  word: string
  match: string
  lang: string
}

const AccordionContent = (props: Props) => {
  const { t } = useTranslation()

  const examples = accordionList(
    props.data,
    props.data.Example,
    capitalizeFirstLetter(
      t('accordion_example', { count: props.data.Example?.length })
    ),
    props
  )

  const compounds = accordionList(
    props.data,
    props.data.Compound,
    'Sammansättningar',
    props
  )
  const idioms = accordionList(props.data, props.data.Idiom, 'Uttryck', props)
  const derivations = accordionList(
    props.data,
    props.data.Derivation,
    'Avledningar',
    props
  )
  let antonyms = <></>
  let comments = <></>
  let synonyms = <></>

  if (props.lang !== 'swe') {
    comments = (
      <>{accordionList(props.data, props.data.Comment, 'Kommentarer', props)}</>
    )
    antonyms = (
      <>
        {accordionList(
          props.data,
          props.data.Antonym,
          capitalizeFirstLetter(
            // Using i18next plural handling
            // https://www.i18next.com/translation-function/plurals#singular-plural
            t('accordion_antonym', { count: props.data.Antonym?.length })
          ),
          props
        )}
      </>
    )
    synonyms = (
      <>
        {accordionList(
          props.data,
          props.data.Synonym,
          capitalizeFirstLetter(
            t('accordion_synonym', { count: props.data.Synonym?.length })
          ),
          props
        )}
      </>
    )
  }

  return (
    <>
      {examples}
      {compounds}
      {idioms}
      {derivations}
      {antonyms}
      {comments}
      {synonyms}
    </>
  )
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getList = (data: DataContent[]) => {
  if (data !== undefined) {
    return data
  } else {
    return []
  }
}

export const checkLength = (dataType?: DataContent[]) => {
  if (dataType !== undefined) {
    return getList(dataType).length
  } else {
    return 0
  }
}

const accordionContentTitle = (
  data: Data | LexemeData,
  title: string,
  lang: string
) => {
  const list = []
  let counter = 0

  list.push(checkLength(data.Compound))
  list.push(checkLength(data.Example))
  list.push(checkLength(data.Idiom))
  list.push(checkLength(data.Derivation))
  list.push(checkLength(data.Antonym))
  list.push(checkLength(data.Synonym))

  if (lang !== 'swe') {
    list.push(checkLength(data.Comment))
    list.push(checkLength(data.Reference))
  } else {
    list.push(0)
    list.push(0)
  }

  for (var i = 0; i < list.length; i++) {
    if (list[i] === 0) {
      counter++
    }
  }

  if (counter !== 7) {
    return <div className={'accordion-content-title'}>{title}</div>
  }
}

const accordionListItems = (item: AccordionContentData) => {
  let returnValue = {}
  let swedish = <>{item.Swedish}</>

  if (item.Highlight !== undefined) {
    swedish = <mark>{item.Swedish}</mark>
  }

  if (item.Translation !== undefined) {
    returnValue = (
      <>
        <Row>
          <Col>
            <li className={'leftColumnStyle'} value='♦'>
              {swedish}
            </li>
          </Col>
          <Col>
            <li className={'rightColumnStyle'} value=''>
              {item.Translation}
            </li>
          </Col>
        </Row>
      </>
    )
  } else if (item.Translation === undefined && swedish !== undefined) {
    returnValue = <li value='♦'>{swedish}</li>
  }

  if (returnValue !== {}) {
    return returnValue
  }
}

const accordionList = (
  data: Data | LexemeData,
  listData: DataContent[] | undefined,
  title: string,
  props: Props
) => {
  const word = props.word
  const match = props.match
  const lang = props.lang

  let loop: DataContent[] = []
  const list: AccordionContentData[] = []

  if (listData !== undefined) {
    loop = getList(listData)
  }

  switch (title) {
    case 'Sammansättningar':
    case 'Avledningar':
    case 'Kommentarer':
      if (loop.length === 1) {
        title = title.substr(0, title.length - 2)
      }

      break
  }

  if (title === 'Motsatsord') {
    if (data.Antonym === undefined && data.Reference !== undefined) {
      data.Reference.forEach(item => {
        if (item.Type === 'antonym' && data.Reference !== undefined)
          loop = getList(data.Reference)
      })
    }
  }

  loop.forEach((element: DataContent) => {
    if (element.Text !== undefined) {
      const swedish = element.Text
      const highlight =
        swedish.toLowerCase() === word.toLowerCase() ||
        swedish.toLowerCase() === match.toLowerCase()

      if (highlight) {
        if (element.XText === undefined) {
          list.push({ Swedish: swedish, Highlight: 'True' })
        } else {
          list.push({
            Swedish: swedish,
            Translation: element.XText,
            Highlight: 'True'
          })
        }
      } else {
        if (element.Description !== undefined) {
          list.push({
            Swedish:
              swedish + ' (' + element.Description + ')' + element.Definition
          })
        } else if (element.Definition !== undefined) {
          list.push({ Swedish: swedish + ' - ' + element.Definition })
        } else if (element.XText !== undefined) {
          list.push({ Swedish: swedish, Translation: element.XText })
        } else {
          list.push({ Swedish: swedish })
        }
      }
    } else if (element.Value !== undefined) {
      const value = element.Value

      if (title === 'Motsatsord') {
        list.push({ Swedish: value, Link: 'True' })
      } else {
        list.push({ Swedish: value })
      }

      if (element.XValue !== undefined) {
        const translation = element.XValue

        if (title === 'Motsatsord') {
          list.push({ Swedish: value, Translation: translation, Link: 'True' })
        } else {
          list.push({ Swedish: value, Translation: translation })
        }
      }
    }
  })

  if (list.length > 0) {
    return (
      <div className='accordion-content-example pr-3 pl-3 pb-0 text-muted'>
        {accordionContentTitle(data, title, lang)}
        <ol className='pl-0' style={{ columns: '1' }}>
          {list.map((item: AccordionContentData, index: number) => (
            <div key={index}>{accordionListItems(item)}</div>
          ))}
        </ol>
      </div>
    )
  }
}

export default AccordionContent
