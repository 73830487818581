import Accordions from './Accordions'
import { Post, LexemeData } from './types'
import Definition from './Definition'

interface Props {
  post: Post
  word: string
  match: string
  posts: Post[]
  lexemes: LexemeData[]
  lang: string
  activeIndex: number[]
}

const PostContent = (props: Props) => {
  const post = props.post
  const multilingualMode = post.data.Lexeme === undefined
  const swedishMode = post.data.Lexeme
  const englishMode = Array.isArray(post.data)

  if (englishMode) {
    return (
      <>
        {post.data.map((enData: any, index: number) => (
          // TODO consider using li > ol
          <div key={index} className='lex-id'>
            {<Definition data={post.data[index]} exampleCounter={index} />}
            <Accordions
              enData={enData}
              activeIndex={props.activeIndex}
              currentPost={post}
              word={props.word}
              lang={props.lang}
              match={props.match}
              posts={props.posts}
              lexemes={props.lexemes}
            />
          </div>
        ))}
      </>
    )
  } else if (multilingualMode) {
    return (
      <>
        <div className='lex-id'>
          {<Definition lang={props.lang} data={post.data} />}
          {Abbreviation(post.data.Abbreviate?.Spec, post.data.Abbreviate?.Text)}
          {AddString(post.data.Usage, 'usage', 'Användning: ')}
          {AddString(post.data.XSynonym, 'title synonym')}
          {AddString(post.data.Explanation, 'title explanation')}
          {AddString(post.data.XExplanation, 'title explanation')}
          <Accordions
            activeIndex={props.activeIndex}
            currentPost={post}
            word={props.word}
            match={props.match}
            posts={props.posts}
            lang={props.lang}
          />
        </div>
      </>
    )
  } else if (swedishMode !== undefined) {
    swedishMode.forEach((item: LexemeData) => {
      if (item.Cycle !== undefined) {
        item.Cycle.forEach(ccl => {
          if (!swedishMode.includes(ccl)) {
            swedishMode.push(ccl)
          }
        })
      }
    })

    return (
      <>
        {swedishMode.map((lex: LexemeData, index: number) => (
          <div key={index} className='lex-id'>
            {
              <Definition
                lang={props.lang}
                data={post.data}
                swedishData={lex}
              />
            }
            {Abbreviation(
              post.data.Abbreviate?.Spec,
              post.data.Abbreviate?.Text
            )}
            {AddString(post.data.Usage, 'usage', 'Användning: ')}
            <Accordions
              activeIndex={props.activeIndex}
              currentPost={post}
              word={props.word}
              lang={props.lang}
              match={props.match}
              posts={props.posts}
              lexemes={props.lexemes}
              lex={lex}
            />
          </div>
        ))}
      </>
    )
  } else {
    return <></>
  }
}

const AddString = (string?: string, className?: string, preString?: string) => {
  if (string) {
    return (
      <div className={className}>
        {preString}
        {string}
      </div>
    )
  }
}

const Abbreviation = (spec?: string, text?: string) => {
  if (spec && text) {
    return (
      <div className={'abbrev'}>
        {spec.charAt(0).toUpperCase() + spec.slice(1)}: {text}
      </div>
    )
  }
}

export default PostContent
