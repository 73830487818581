import Alert from 'react-bootstrap/Alert'
import { WarningStatus } from '.';

interface Props {
    status: WarningStatus
    message: string
}

const Warning = (props: Props) => {

    switch (props.status) {

        case WarningStatus.NONE:
            return <></>
        case WarningStatus.WARNING:
            return (<Alert variant='warning'>
                {props.message}
            </Alert>)
        case WarningStatus.ERROR:
            return (<Alert variant='danger'>
                {props.message}
            </Alert>)
    }

}

export default Warning