import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

interface Props {
  phonfile: string
  audioFiles: string[]
}

const Speak = (props: Props) => {
  let phonfile: string = props.phonfile

  if (phonfile !== undefined) {
    phonfile = props.phonfile
      .replaceAll(' ', '%20')
      .replaceAll('å', '0345')
      .replaceAll('ä', '0344')
      .replaceAll('ö', '0366')
      .replaceAll('Å', '0305')
      .replaceAll('Ä', '0304')
      .replaceAll('Ö', '0326')
      .replaceAll('é', '0351')
  }

  const status = props.audioFiles.includes(phonfile)

  if (status) {
    const baseUrl = 'https://dictionary.oribisoftware.com/sounds-lexin/mp3/'
    const audio = new Audio()

    return (
      <>
        {status ? (
          <Button
            className='pl-0 ml-1 speak-word'
            variant='link'
            onClick={e => {
              e.preventDefault()
              if (audio.src.length === 0) {
                if (!phonfile.startsWith('v2/'))
                  phonfile = encodeURIComponent(phonfile)
                audio.src = baseUrl + phonfile
              }
              audio.play()
            }}
          >
            <FontAwesomeIcon icon={faVolumeUp} />
          </Button>
        ) : (
          <></>
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default Speak
