import React from 'react'
import Search from './Search'
import Suggestions from './Suggestions'
import Start from './Start'
import Posts from './Posts'
import Warning from './Warning'
import Spinner from 'react-bootstrap/Spinner'
import {
  Post,
  Options,
  LexemeData,
  Data,
  AccordionData,
  DataContent
} from './types'
import Language from './Language'
import i18n from 'i18next'
import { Trans } from 'react-i18next'
import { addWindowListener } from '../index'

const isPathnameEnglish: boolean = window.location.pathname
  .slice(0, 3)
  .includes('/en')

interface Props {
  isIframe: boolean
  initialWord?: string
}

interface State {
  activeIndex: number[]
  word: string
  warning: WarningStatus
  postsToSuggest: Post[]
  postsToShow: Post[]
  match: string
  query: string
  audioList: string[]
  isLoaded: boolean
  url: string
  showFrontPage: boolean
  chosenLanguage: string
  chosenLanguageLabel: string
  options: Options[]
  lexemes: LexemeData[]
  datas: Data[]
  warningMessage: string
}

export enum WarningStatus {
  NONE,
  WARNING,
  ERROR
}

export default class Dictionary extends React.Component<Props, State> {
  maxStringSearch: number
  maximumSuggestionDistance: number

  constructor(props: Props) {
    super(props)

    this.state = {
      warningMessage: '',
      postsToSuggest: [],
      postsToShow: [],
      lexemes: [],
      url: '',
      warning: WarningStatus.NONE,
      datas: [],
      activeIndex: [],
      query: new URL(window.location.href).searchParams.get('q') || '',
      match: '',
      audioList: [],
      isLoaded: false,
      showFrontPage: false,
      word: new URL(window.location.href).searchParams.get('q') || '',
      chosenLanguage: window.location.pathname.slice(0, 3).includes('/en')
        ? 'eng'
        : new URL(window.location.href).searchParams.get('l') ||
          localStorage.getItem('langValue') ||
          'swe',
      chosenLanguageLabel: localStorage.getItem('langLabel') || 'svensk',
      options: [
        { show: 'svensk', text: 'Svenska', value: 'swe' },
        { show: 'albansk', text: 'Albanska', value: 'alb' },
        { show: 'amharisk', text: 'Amhariska', value: 'amh' },
        { show: 'arabisk', text: 'Arabiska', value: 'ara' },
        { show: 'azerbajdzjansk', text: 'Azerbajdzjanska', value: 'azj' },
        { show: 'bosnisk', text: 'Bosniska', value: 'bos' },
        { show: 'engelsk', text: 'Engelska', value: 'eng' },
        { show: 'finsk', text: 'Finska', value: 'fin' },
        { show: 'grekisk', text: 'Grekiska', value: 'gre' },
        { show: 'kroatisk', text: 'Kroatiska', value: 'hrv' },
        { show: 'nordkurdisk', text: 'Nordkurdiska', value: 'kmr' },
        { show: 'pashtunsk', text: 'Pashto', value: 'pus' },
        { show: 'persisk', text: 'Persiska', value: 'per' },
        { show: 'rysk', text: 'Ryska', value: 'rus' },
        {
          show: 'serbisk',
          text: 'Serbiska (Kyrilliska)',
          value: 'srp_cyrillic'
        },
        { show: 'serbisk', text: 'Serbiska (Latin)', value: 'srp_latin' },
        { show: 'somalisk', text: 'Somaliska', value: 'som' },
        { show: 'spansk', text: 'Spanska', value: 'spa' },
        { show: 'sydkurdisk', text: 'Sydkurdiska', value: 'sdh' },
        { show: 'turkisk', text: 'Turkiska', value: 'tur' },
        { show: 'tigrinsk', text: 'Tigrinska', value: 'tir' }
      ]
    }
    this.maxStringSearch = 40
    this.maximumSuggestionDistance = 19
  }

  componentDidMount() {
    window.onpopstate = (ev: PopStateEvent) => {
      const word = new URL(window.location.href).searchParams.get('q') || ''
      const language = new URL(window.location.href).searchParams.get('l') || ''

      this.setState({
        word: word,
        chosenLanguage: language
      })
    }

    let word = this.state.word

    try {
      word = decodeURIComponent(word)
    } catch (error) {
      word = new URL(window.location.href).searchParams.get('q') || ''
    }

    if (word.length > this.maxStringSearch) {
      word = ''
    }

    this.FetchData(word)

    if (this.props.isIframe) {
      addWindowListener((key, value) => {
        if (key === 'lookupWord' && value) {
          this.updateDictionary(value)
        }
      })
    }

    if ( isPathnameEnglish ) {
      document.documentElement?.setAttribute('lang', 'en');
    }
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevState.word !== this.state.word ||
      prevState.chosenLanguage !== this.state.chosenLanguage
    ) {
      this.setState({
        activeIndex: [],
        isLoaded: false,
        warning: WarningStatus.NONE
      })

      this.FetchData(this.state.word)
    }
  }

  FetchData = (word: string) => {
    if (word !== '') {
      // FIXME did not get i18n to work here, look up solution
      window.document.title = isPathnameEnglish
        ? "Oribi's dictionary | " + word
        : 'Oribis ordbok | ' + word

      Promise.all([
        this.fetchAudioFiles(),
        this.fetchDictionaryData(word, this.state.chosenLanguage)
      ]).then(result => {
        let audio: string[] = []

        if (result[0] !== undefined) {
          audio = result[0]
        }

        if (result[1] !== undefined) {
          this.prepareData(result[1], word, audio)
        }

        const height = document.querySelector('#root')?.scrollHeight

        if (height !== undefined)
          window.top?.postMessage(
            {
              scrollHeight: height
            },
            '*'
          )
      })
    } else {
      // FIXME did not get i18n to work here, look up solution
      window.document.title = isPathnameEnglish
        ? "Oribi's dictionary"
        : 'Oribis ordbok'

      this.setState({
        showFrontPage: true,
        isLoaded: false
      })
    }
  }

  LanguageLabel = (lang: string) => {
    const { options } = this.state
    let chosenLanguageLabel = this.state.chosenLanguageLabel

    for (var i = 0; i < options.length; i++) {
      if (options[i].value === lang) {
        chosenLanguageLabel = options[i].show
        break
      }
    }

    return chosenLanguageLabel
  }

  autoExpandFeature = (
    match: string,
    lexemes?: LexemeData[],
    datas?: Data[]
  ) => {
    const list: number[] = []
    let dataPoint: DataContent[] = []
    let loop: AccordionData[] = []

    if (lexemes !== undefined && lexemes.length > 0) {
      loop = lexemes
    } else if (datas !== undefined) {
      loop = datas
    }

    loop.forEach((item: AccordionData) => {
      if (item.Compound !== undefined) {
        dataPoint = item.Compound
      } else if (item.Derivation !== undefined) {
        dataPoint = item.Derivation
      } else if (item.Example !== undefined) {
        dataPoint = item.Example
      } else if (item.Synonym !== undefined) {
        dataPoint = item.Synonym
      }

      dataPoint.forEach((element: DataContent) => {
        if (
          element.Text?.toLowerCase() === this.state.word.toLowerCase() ||
          element.Text?.toLowerCase() === match.toLowerCase()
        ) {
          list.push(loop.indexOf(item))
        }
      })
    })

    return list
  }

  fetchAudioFiles = async () => {
    try {
      const res = await fetch(
        'https://dictionary.oribisoftware.com/sounds-lexin/index.php'
      )
      const data: string[] = res.ok
        ? await res.json()
        : await Promise.resolve([])

      return data || []
    } catch (error) {}
  }

  fetchDictionaryData = async (word: string, language: string) => {
    try {
      const baseUrl = 'https://ordboken.orilango.se'

      const url = !isPathnameEnglish
        ? // TODO use toLowerCaser() or not?
          `${baseUrl}/?language=${language}&word=${word}`
        : `${baseUrl}/eng/?language=eng&word=${word}`

      const res = await fetch(url)
      const result: Post[] = res.ok
        ? await res.json()
        : await Promise.resolve([])

      return result || []
    } catch (error) {
      this.setState({
        warningMessage: i18n.t('index_warning_not_available'),
        warning: WarningStatus.ERROR
      })
    }
  }

  prepareData = (result: Post[], word: string, audio: string[]) => {
    const posts = () => {
      const list: Post[] = []

      if (result !== undefined && result.length > 0) {
        result.forEach((item: Post) => {
          if (item.distance === 0) {
            list.push(item)
          }
        })
      }
      return list
    }

    const datas = () => {
      const list: Data[] = []

      if (result !== undefined && result.length > 0) {
        result.forEach((item: Post) => {
          list.push(item.data)
        })
      }
      return list
    }

    const suggestions = () => {
      const list: Post[] = []

      if (result !== undefined && result.length > 0) {
        result.forEach((item: Post) => {
          if (
            item.distance > 0 &&
            item.distance < this.maximumSuggestionDistance
          ) {
            list.push(item)
          }
        })
      }
      return list
    }

    const postList: Post[] = posts()
    const suggestionList: Post[] = suggestions()
    let warningType = WarningStatus.NONE
    let warningText = ''

    // If we do not find what the user is looking for we need to move suggestions to posts
    if (postList.length === 0) {
      if (suggestionList.length > 0) {
        postList.push(suggestionList[0])
        suggestionList.shift()

        while (
          suggestionList[0] !== undefined &&
          suggestionList[0].distance === postList[0].distance
        ) {
          if (!postList.includes(suggestionList[0])) {
            postList.push(suggestionList[0])
            suggestionList.shift()
          }
        }
      }

      const firstPost = postList[0]

      if (firstPost !== undefined && firstPost.data.Value !== undefined) {
        const distanceToFirstPost = firstPost.distance

        if (
          distanceToFirstPost > 0
          // FIXME remove or use?
          // valueOfFirstPost !== word.toLowerCase() &&
          // this.state.query.toLowerCase() !== matchOfFirstPost
        ) {
          warningType = WarningStatus.WARNING
          warningText = i18n.t('index_warning_first_post', {
            first_post: firstPost.data.Value
          })
        }
      } else if (word !== '') {
        warningType = WarningStatus.ERROR
        warningText = i18n.t('index_warning_text', { word: word })
      }
    }

    let match = ''

    if (postList[0] !== undefined) {
      match = postList[0].match.replace(/\|/g, '')
    }

    const lexemes = () => {
      const list: LexemeData[] = []

      if (postList !== undefined && postList.length > 0) {
        postList.forEach((item: Post) => {
          if (item.data.Lexeme !== undefined) {
            item.data.Lexeme.forEach((item: LexemeData) => {
              list.push(item)

              if (item.Cycle !== undefined) {
                item.Cycle.forEach((ccl: LexemeData) => {
                  list.push(ccl)
                })
              }
            })
          }
        })
      }

      return list
    }

    var url = new URL(window.location.href)
    var search_params = url.searchParams

    this.setState({
      postsToSuggest: suggestionList,
      postsToShow: postList,
      match: match,
      lexemes: lexemes(),
      datas: datas(),
      chosenLanguageLabel: this.LanguageLabel(this.state.chosenLanguage),
      activeIndex: this.autoExpandFeature(match, lexemes(), datas()),
      showFrontPage: false,
      isLoaded: true,
      audioList: audio,
      url: search_params.toString(),
      warning: warningType,
      warningMessage: warningText
    })
  }

  updateDictionary = (word: string) => {
    var url = new URL(window.location.href)
    var search_params = url.searchParams

    if (word !== '') {
      search_params.set('q', word)
      if (!isPathnameEnglish) {
        search_params.set('l', this.state.chosenLanguage)
      }
      window.history.pushState('', '', '?' + search_params.toString())
    } else {
      // fallback to /en if english dictionary was used
      if (!isPathnameEnglish) {
        window.history.replaceState('', '', '/')
      } else {
        window.history.replaceState('', '', '/en')
      }
    }

    if (word !== this.state.word)
      this.setState({
        word: word,
        showFrontPage: false
      })
  }

  updateLanguage = (lang: string, label: string) => {
    var url = new URL(window.location.href)
    var search_params = url.searchParams

    if (this.state.word !== '') {
      search_params.set('q', this.state.word)
      search_params.set('l', lang)
      window.history.pushState('', '', '?' + search_params.toString())
    }

    this.setState({
      chosenLanguage: lang,
      chosenLanguageLabel: label
    })
  }

  render = () => {
    const {
      warningMessage,
      warning,
      postsToSuggest,
      lexemes,
      activeIndex,
      chosenLanguage,
      postsToShow,
      match,
      audioList,
      word,
      showFrontPage,
      isLoaded
    } = this.state
    const showLoader = !isLoaded && word !== ''
    const footerUrl = {
      sv: 'https://www.isof.se/om-oss/publikationer/minoritetssprak/lexin.html',
      en: 'https://wordnet.princeton.edu'
    }

    return (
      <>
        <Search
          handleSearch={this.updateDictionary}
          maxSearchLength={this.maxStringSearch}
          word={word}
        />

        {!isPathnameEnglish ? (
          <Language
            changeLanguage={this.updateLanguage}
            options={this.state.options}
            chosenLanguageLabel={this.state.chosenLanguageLabel}
          />
        ) : (
          <div className='hidden-language-padding'></div>
        )}

        {showLoader ? (
          <div className={'loader-segment text-center'}>
            <Spinner animation='border' />
          </div>
        ) : (
          <></>
        )}

        {showFrontPage ? <Start /> : <></>}

        {isLoaded ? (
          <>
            <Warning status={warning} message={warningMessage} />

            <Posts
              isPathnameEnglish={isPathnameEnglish}
              posts={postsToShow}
              match={match}
              audioList={audioList}
              lexemes={lexemes}
              activeIndex={activeIndex}
              lang={chosenLanguage}
              word={word}
              handleLinkClick={this.updateDictionary}
            />

            <Suggestions
              data={postsToSuggest}
              handleClick={this.updateDictionary}
            />

            <footer>
              <Trans i18nKey='index_footer_based_on'>
                Baserad på
                <a
                  href={!isPathnameEnglish ? footerUrl.sv : footerUrl.en}
                  rel='noreferrer'
                  target='_blank'
                >
                  Lexins
                </a>
                ordböcker.
              </Trans>
            </footer>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }
}
