import { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { useTranslation } from 'react-i18next'
import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface Props {
  maxSearchLength: number
  word: string
  handleSearch: (arg: string) => void
}

let timeout: NodeJS.Timeout | number

const Search = (props: Props) => {
  const { t } = useTranslation()

  const [query, setQuery] = useState(props.word)
  const input = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setQuery(props.word)
    input.current?.focus()
  }, [props.word])

  return (
    <Form
      className={'search-dictionary'}
      onSubmit={e => {
        e.preventDefault()
        props.handleSearch(query)
      }}
    >
      <InputGroup>
        <FormControl
          ref={input}
          as='input'
          type='text'
          placeholder={t('search_placeholder')}
          aria-label={t('search_placeholder')}
          value={query}
          id='searchBar'
          aria-describedby='basic-addon2'
          onChange={({ target: { value: searchString } }) => {
            setQuery(searchString)
            if (timeout) {
              clearTimeout(timeout as NodeJS.Timeout)
            }
            timeout = setTimeout(() => {
              props.handleSearch(searchString)
            }, 1000)
          }}
          data-testid='form-control-element'
        />

        <InputGroup.Append>
          <Button variant='secondary' type='submit'>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  )
}

export default Search
