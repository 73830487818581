import Card from 'react-bootstrap/Card'
import { Inflexiondata, DataContent } from './types'

interface Props {
  data: DataContent[]
}

const Inflexions = (props: Props) => {
  if (props.data.length > 0) {
    const list: Inflexiondata[] = []

    props.data.forEach((item: DataContent) => {
      if (item.Text !== '–') {
        if (item.Spec !== undefined) {
          if (item.Spec === '!') {
            list.push({ Text: item.Text, Suffix: item.Spec })
          } else {
            list.push({ Text: item.Text, Prefix: item.Spec })
          }
        } else {
          list.push({ Text: item.Text })
        }
      }
    })

    const status = list.length > 0

    const formatInflexions = (
      item: Inflexiondata,
      list: Inflexiondata[],
      index: number
    ) => {
      let inflection = <>{item.Text}</>

      if (item.Suffix !== undefined) {
        inflection = (
          <>
            {item.Text}
            {item.Suffix}
          </>
        )
      } else if (item.Prefix !== undefined) {
        inflection = (
          <>
            <small className='prefix'>{item.Prefix} </small>
            {item.Text}
          </>
        )
      }

      if (list.length === 1 || list.indexOf(item) === list.length - 1) {
        return (
          <span className='inflexion' key={index}>
            {inflection}
          </span>
        )
      } else {
        return (
          <span className='inflexion' key={index}>
            {inflection},{' '}
          </span>
        )
      }
    }

    return (
      <>
        {status ? (
          <Card.Subtitle as='div' className='inflexions'>
            ⟨
            {list.map((item: Inflexiondata, index: number) =>
              formatInflexions(item, list, index)
            )}
            ⟩
          </Card.Subtitle>
        ) : (
          <></>
        )}
      </>
    )
  } else {
    return <></>
  }
}

export default Inflexions
