import React from 'react'
import Card from 'react-bootstrap/Card'
import { Post, DataContent, LexemeData } from './types'

interface Props {
  currentPost: Post
  lexeme?: LexemeData[]
  handleLinkClick: (arg: string) => void
}

type referenceLinkClick = (
  event: React.MouseEvent<HTMLSpanElement, MouseEvent>
) => void

const References = (props: Props) => {
  let referenceList: string[] = []
  let referenceValue = ''
  let referenceType = ''
  let textBefore = ''
  let textAfterReference = ''
  let references = props.currentPost.data.Reference

  if (references === undefined && props.lexeme !== undefined) {
    if (props.currentPost.data.Lexeme !== undefined)
      props.currentPost.data.Lexeme.forEach(element => {
        references = element.Reference
      })
  }

  if (references !== undefined && references.length > 0) {
    references.forEach((item: DataContent) => {
      if (item.Value === undefined) {
        if (typeof item.VALUE === 'string') {
          referenceValue = item.VALUE
        }
        if (typeof item.TYPE === 'string') {
          referenceType = item.TYPE
        }
      } else {
        if (typeof item.Value === 'string') {
          referenceValue = item.Value
        }
        if (typeof item.Type === 'string') {
          referenceType = item.Type
        }
      }

      if (referenceValue !== undefined) {
        if (referenceType === 'compare') {
          textBefore = 'Jämför:'
          if (item.Spec !== undefined) {
            textAfterReference = item.Spec
          }
        } else if (referenceType === 'see') {
          textBefore = 'Se:'
        } else if (referenceType === 'antonym') {
          textBefore = 'Motsatsord:'
        }

        switch (referenceType) {
          case 'compare':
          case 'see':
          case 'antonym':
            if (referenceValue.includes(',')) {
              referenceList = referenceValue.replace(' ', '').split(',')
            } else {
              referenceList.push(referenceValue)
            }

            break
        }
      }
    })
  }

  const handleLinkClick: referenceLinkClick = e => {
    if (e.currentTarget.textContent)
      props.handleLinkClick(e.currentTarget.textContent)
  }

  const status = referenceList.length > 0

  return (
    <>
      {status ? (
        <Card.Subtitle as='div' className='references'>
          <Card.Text className='see-other'>
            {textBefore}{' '}
            {referenceList.map((item: string, index: number) => (
              <span key={index}>
                <Card.Link href='#' className='link' onClick={handleLinkClick}>
                  {item}
                </Card.Link>
                {ReferenceSpec(textAfterReference)}
                {Spacer(item, referenceList)}{' '}
              </span>
            ))}
          </Card.Text>
        </Card.Subtitle>
      ) : (
        <></>
      )}
    </>
  )
}

const ReferenceSpec = (textAfterReference: string) => {
  if (textAfterReference.length > 0) {
    return <small> {textAfterReference}</small>
  }
}

const Spacer = (item: string, referenceList: string[]) => {
  if (
    referenceList.indexOf(item) !== referenceList.length - 1 &&
    referenceList.length !== 1
  ) {
    return ','
  }
}

export default References
