import ReactDOM from 'react-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { Suspense } from 'react'
import { Spinner } from 'react-bootstrap'

import './bootstrap.scss'
import './index.css'
import Dictionary from './Dictionary/index'
import reportWebVitals from './reportWebVitals'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'sv'],
    fallbackLng: 'sv',
    detection: {
      // order and from where user language should be detected
      order: ['path']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    }
  })

// Handle iframe parent
const isIframe = window.top !== window

export const addWindowListener = (
  callback: (key: string, value?: string) => void
) => {
  const trustedOrigins = [
    'https://localhost:3000',
    'https://dev--oribi-speak-light.netlify.app',
    'https://oribi-speak-light.netlify.app'
  ]

  const receiveMessage = (event: MessageEvent) => {
    const { origin, data } = event
    if (!trustedOrigins.includes(origin) || typeof data !== 'string') return

    const [key, value] = data.split('=')
    callback(key, value)
  }
  window.addEventListener('message', receiveMessage, false)
}

if (isIframe) {
  addWindowListener((key, value) => {
    if (key === 'host' && value) {
      document.documentElement.setAttribute(key, value)
    }

    if (key === 'postSelectionText') {
      const text = document.getSelection()?.toString() || ''
      window.parent.postMessage('selectionText=' + text, '*')
    }
  })

  // Tell parent window when DOM is finished loading
  document.addEventListener('DOMContentLoaded', () => {
    window.parent.postMessage('DOMContentLoaded', '*')
  })
} else {
  const body = document.body
  body.classList.add('notInIframe')

  document.addEventListener('selectionchange', () => {
    window.parent.postMessage('selectionChange', '*')
  })
}

// Get URL query parameter
let initialWord = ''
const urlQuery = window.location.href.match(/[?&]query=([^?&]+)/)
if (urlQuery !== null) initialWord = urlQuery[1]

const languageLoadingMarkup = (
  <div className={'loader-segment text-center'}>
    <Spinner animation='border' />
  </div>
)

ReactDOM.render(
  <Suspense fallback={languageLoadingMarkup}>
    <Dictionary isIframe={isIframe} initialWord={initialWord} />
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
