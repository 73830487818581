import i18n from 'i18next'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import { Post, DataContent, LexemeData, Data } from './types'
import AccordionContent from './AccordionContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

interface Props {
  enData?: any
  activeIndex: number[]
  currentPost: Post
  posts: Post[]
  word: string
  match: string
  lang: string
  lexemes?: LexemeData[]
  lex?: LexemeData
}

const handleClicks = (e: any) => {
  if (e.currentTarget.className.includes('faCaretRight')) {
    e.target.classList.remove('faCaretRight')
    e.target.classList.add('faCaretDown')
  } else {
    e.target.classList.remove('faCaretDown')
    e.target.classList.add('faCaretRight')
  }
}

const Accordions = (props: Props) => {
  let data: Data | LexemeData
  let index = props.posts.indexOf(props.currentPost)

  if (props.lex !== undefined && props.lexemes !== undefined) {
    data = props.lex
    index = props.lexemes.indexOf(props.lex)
  } else if (props.enData) {
    data = props.enData
  } else {
    data = props.currentPost.data
  }

  const listLength = AccordionListLength(data, props.lang)
  const status = listLength > 0

  let collapseAccordion = '1'
  let iconClassName = 'faCaretRight'

  if (props.activeIndex.includes(index)) {
    collapseAccordion = '0'
    iconClassName = 'faCaretDown'
  }

  return (
    <>
      {status ? (
        <Accordion defaultActiveKey='0'>
          <Accordion.Toggle
            onClick={handleClicks}
            className={`${iconClassName} text-muted full-width`}
            as={Button}
            id='dropdown-basic'
            variant='link'
            eventKey={collapseAccordion}
            data-testid='accordion-data-element'
          >
            <FontAwesomeIcon className='mr-1' icon={faCaretRight} />{' '}
            {AccordionTitle(data, props.lang)}
          </Accordion.Toggle>
          <Accordion.Collapse className='border' eventKey={collapseAccordion}>
            <AccordionContent
              data={data}
              word={props.word}
              match={props.match}
              lang={props.lang}
            />
          </Accordion.Collapse>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  )
}

const AccordionListLength = (data: Data | LexemeData, lang: string) => {
  let commentLength = GetDataList(data.Comment).length
  const compsLength = GetDataList(data.Compound).length
  const exLength = GetDataList(data.Example).length
  const synonymsLength = GetDataList(data.Synonym).length
  const idiomsLength = GetDataList(data.Idiom).length
  const derivationLength = GetDataList(data.Derivation).length
  let antonymLength = GetDataList(data.Antonym).length

  if (data.Antonym === undefined && data.Reference !== undefined) {
    data.Reference.forEach(item => {
      if (item.Type === 'antonym' && data.Reference !== undefined)
        antonymLength = GetDataList(data.Reference).length
    })
  }

  if (lang === 'swe') {
    commentLength = 0
    antonymLength = 0
  }

  const listLength =
    exLength +
    compsLength +
    idiomsLength +
    derivationLength +
    antonymLength +
    commentLength +
    synonymsLength

  return listLength
}

const GetDataList = (data: DataContent[] | undefined) => {
  const list = []

  if (data !== undefined) {
    list.push(data)
  }

  if (list[0] !== undefined) {
    return list[0]
  } else {
    return list
  }
}

const TitleFormat = (listLength: number, title: string) => {
  if (listLength === 1) {
    return { length: listLength, text: title.substr(0, title.length - 2) }
  } else {
    return { length: listLength, text: title }
  }
}

const AccordionTitle = (data: Data | LexemeData, lang: string) => {
  let title = ''
  let antonymLength = GetDataList(data.Antonym).length

  const exampleListLength = GetDataList(data.Example).length
  const synonymListLength = GetDataList(data.Synonym).length
  const compoundListLength = GetDataList(data.Compound).length
  const idiomListLength = GetDataList(data.Idiom).length
  const derivationLength = GetDataList(data.Derivation).length
  const commentLength = GetDataList(data.Comment).length
  const listLengths = []
  const titleList = []
  const listLength = AccordionListLength(data, lang)

  if (data.Antonym === undefined && data.Reference !== undefined) {
    data.Reference.forEach(item => {
      if (item.Type === 'antonym' && data.Reference !== undefined)
        antonymLength = GetDataList(data.Reference).length
    })
  }

  listLengths.push({
    length: exampleListLength,
    text: i18n.t('accordion_example', { count: exampleListLength })
  })
  listLengths.push(TitleFormat(compoundListLength, 'sammansättningar'))
  listLengths.push({ length: idiomListLength, text: 'uttryck' })
  listLengths.push(TitleFormat(derivationLength, 'avledningar'))

  if (lang !== 'swe') {
    listLengths.push(TitleFormat(commentLength, 'kommentarer'))
    listLengths.push({
      length: antonymLength,
      text: i18n.t('accordion_antonym', { count: antonymLength })
    })
    listLengths.push({
      length: synonymListLength,
      text: i18n.t('accordion_synonym', { count: synonymListLength })
    })
  } else {
    listLengths.push({ length: 0, text: '' })
    listLengths.push({ length: 0, text: '' })
  }

  for (var i = 0; i < listLengths.length; i++) {
    if (listLengths[i].length > 0) {
      titleList.push(listLengths[i].text)
    }
  }

  for (var y = 0; y < titleList.length; y++) {
    if (y === titleList.length - 1 && titleList.length > 1) {
      titleList[y] = ' & ' + titleList[y]
      break
    }
  }

  const tempTitle = titleList
    .toString()
    .replace(', &', ' &')
    .replace(/(,)/g, ', ')

  if (tempTitle.length > 0) {
    title =
      tempTitle[0].toUpperCase() + tempTitle.substring(1, tempTitle.length)
  }

  if (listLength > 0) {
    return title
  }
}

export default Accordions
