import i18n from 'i18next'
import Card from 'react-bootstrap/Card'
import { Post } from './types'

interface Props {
  data: Post[]
  handleClick: (arg: string) => void
}

const Suggestions = (props: Props) => {
  const data = FormatSuggestions(props.data)

  const status = data.length > 0

  return (
    <>
      {status ? (
        <Card border='light' className='suggestions'>
          <Card.Body>
            <Card.Title as='h6'>
              {i18n.t('suggestions_did_you_mean')}:
            </Card.Title>
            {data.map((item: Post, index: number) => (
              <Card.Text style={{ display: 'flex' }} key={index}>
                <Card.Link
                  href='#'
                  style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  onClick={() => {
                    if (item.data.Value) props.handleClick(item.data.Value)
                  }}
                >
                  {item.data.Value}
                </Card.Link>
                &nbsp;
                {item.match !== item.data.Value && `(${item.match})`}
                {SuggestionTranslation(item)}
              </Card.Text>
            ))}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </>
  )
}

const FormatSuggestions = (suggestions: Post[]) => {
  const values: string[] = []
  const list: Post[] = []

  suggestions.forEach((post: Post) => {
    let tempValue = ''

    if (post.data.Value !== undefined) {
      const uniqueValue = !values.includes(post.data.Value)

      if (uniqueValue) {
        list.push(post)
        values.push(post.data.Value)

        if (
          post.data.XTranslation !== undefined &&
          post.data.XTranslation.length > 35
        ) {
          let translationList = post.data.XTranslation.split(';')

          if (translationList.length === 1) {
            translationList = post.data.XTranslation.split(',')
          }

          for (var i = 0; i < translationList.length; i++) {
            if (
              tempValue.length + translationList[i].length < 40 &&
              translationList[i] !== ''
            ) {
              tempValue += translationList[i] + ';'
            } else {
              break
            }
          }

          post.data.XTranslation = tempValue
        }
      } else {
        const translation = post.data.XTranslation
        const translationList =
          list[values.indexOf(post.data.Value)].data.XTranslation

        if (translation !== undefined && translationList !== undefined) {
          const translationHasBeenUsed = translationList.includes(
            '; ' + translation
          )

          if (!translationHasBeenUsed) {
            if (translation.length + translationList.length < 40) {
              list[values.indexOf(post.data.Value)].data.XTranslation +=
                '; ' + translation
              list[values.indexOf(post.data.Value)].data.XTranslation = list[
                values.indexOf(post.data.Value)
              ].data.XTranslation?.replace(';;', ';')
            }
          }
        }
      }
    }
  })

  return list.slice(0, 3)
}

const SuggestionTranslation = (post: Post) => {
  const status = post.data.XTranslation !== undefined

  return (
    <>
      {status ? (
        <span className='translated-suggestions'>{post.data.XTranslation}</span>
      ) : (
        <></>
      )}
    </>
  )
}

export default Suggestions
