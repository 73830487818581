import { useTranslation } from 'react-i18next'
import Card from 'react-bootstrap/Card'
import { version } from '../../package.json'

const Start = () => {
  const { t } = useTranslation()

  return (
    <Card className='start-page' border='light'>
      <Card.Body className='start-content'>
        <Card.Title as='h3' className='text-center pt-2'>
          {t('title')}
        </Card.Title>
        <Card.Text className='pr-5 pl-5 pb-3 text-center text-muted'>
          {t('start_content')}
        </Card.Text>
      </Card.Body>
      <Card.Footer className='text-center text-muted'>
        {t('start_developed_by')}{' '}
        <a
          className='link'
          target='_blank'
          rel='noreferrer'
          href='https://oribi.se/'
        >
          Oribi
        </a>
        . Version {version}.
        <div style={{ display: 'none' }}>
          NODE_ENV: {process.env.NODE_ENV}. CONTEXT:{' '}
          {process.env.REACT_APP_CONTEXT}. Secret:{' '}
          {process.env.REACT_APP_SECRET_KEY}.
        </div>
      </Card.Footer>
    </Card>
  )
}

export default Start
