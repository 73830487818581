import Speak from './Speak'
import { Data } from './types'

interface Props {
  data: Data
  audioList: string[]
  word?: string
  wordType?: string
}

// Set dot color based on word class
export function getColorOfWordClass(wordClassParam: string) {
  switch (wordClassParam) {
    case 'adjective':
      return 'rgb(255, 151, 70)' // orange
    case 'noun':
      return 'rgb(242, 41, 25)' // red
    case 'verb':
      return 'rgb(25, 224, 203)' // light blue
    case 'adverb':
      return 'rgb(37, 117, 176)' // blue
    default:
      return 'rgb(68, 68, 68)' // dark gray
  }
}

const PostHeader = (props: Props) => {
  let value = ''
  let audioFile = ''

  if (props.data.Value !== undefined) {
    value = props.data.Value.replace(/\|/g, '')
  }
  if (props.word !== undefined) {
    value = props.word.replace(/\|/g, '')
  }

  if (props.data.Phonetic !== undefined) {
    if (props.data.Phonetic.File !== undefined) {
      audioFile = props.data.Phonetic.File
    }
  }

  return (
    <>
      <div className='swedish-lemma'>
        {addString(props.data.Gendr, 'ART')}
        {value}
        {LemmaVariant(props.data.Variant)}
        <Speak audioFiles={props.audioList} phonfile={audioFile} />
        {LemmaAlternate(props.data)}
        {addString(props.data.Type, 'Type')}
        {addString(props.wordType, 'Type')}
      </div>

      {props.wordType ? (
        <span
          className='word-type-dot'
          style={{ backgroundColor: getColorOfWordClass(props.wordType) }}
        ></span>
      ) : (
        <span className='spacer'></span>
      )}
      {addString(props.data.XTranslation, 'foreign-lemma')}
    </>
  )
}

const addString = (text?: string, className?: string) => {
  if (className === 'Type' && text === 'se') {
    return false
  }

  if (text) {
    return <div className={className}>{text}</div>
  }
}

const LemmaVariant = (variant?: string) => {
  if (variant !== undefined) {
    return <sup>{variant}</sup>
  }
}

const LemmaAlternate = (data: Data) => {
  const alternate: string[] = []

  if (data.Alternate !== undefined) {
    data.Alternate.forEach(element => {
      if (element.Text !== data.Value) {
        alternate.push(element.Text)
      }
    })

    return <span className='alternate-lemma'>({alternate.join(',')})</span>
  }
}

export default PostHeader
