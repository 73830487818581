import Card from 'react-bootstrap/Card'
import Inflexions from './Inflexions'
import References from './References'
import PostHeader from './PostHeader'
import PostContent from './PostContent'
import { Post, LexemeData } from './types'

import groupByWordClass from './groupByWordClass'

interface Props {
  isPathnameEnglish: boolean
  word: string
  match: string
  lang: string
  // posts: Post[]
  posts: any
  lexemes: LexemeData[]
  audioList: string[]
  activeIndex: number[]
  handleLinkClick: (arg: string) => void
}

const Posts = (props: Props) => {
  let { posts } = props

  // "massaging" the object for english dictionary
  if (props.isPathnameEnglish) posts = groupByWordClass(posts)

  const textAlignDirection = (lang: string) => {
    let className = ''

    switch (lang) {
      case 'per':
      case 'ara':
      case 'sdh':
      case 'pus':
        className = ' right-aligned-lang'
        break
    }
    return className
  }

  const alignTextClass = textAlignDirection(props.lang)

  const handlesLinkClick = (value: string) => {
    props.handleLinkClick(value)
  }

  const thereArePostsToShow = posts.length > 0

  if (thereArePostsToShow) {
    return (
      <>
        {posts.map((post: Post, index: number) => (
          <Card
            border='light'
            key={index}
            className={'lemma-content' + alignTextClass}
          >
            <Card.Body className='pb-3 pt-3'>
              <Card.Title as='h4' className='mb-0'>
                <PostHeader
                  data={post.data}
                  audioList={props.audioList}
                  word={posts[index].word}
                  wordType={posts[index].wordType}
                />
              </Card.Title>
              <Inflexions
                data={
                  posts[index].data.Inflection || posts[index].Inflection || []
                }
              />
              <References
                handleLinkClick={handlesLinkClick}
                lexeme={props.lexemes}
                currentPost={posts[index]}
              />
              <PostContent
                post={post}
                word={props.word}
                match={props.match}
                posts={props.posts}
                lexemes={props.lexemes}
                lang={props.lang}
                activeIndex={props.activeIndex}
              />
            </Card.Body>
          </Card>
        ))}
      </>
    )
  } else {
    return <></>
  }
}

export default Posts
